import Modules from '@/store/enums/Modules';
import store from '@/store';
import { Action, Module, Mutation, MutationAction, VuexModule, getModule } from "vuex-module-decorators";
import PaginationRequest from '@/models/general/PaginationRequest';
import { Mutations } from '@/store/commons/MutationsTable';
import { SaveValuesToStore } from '@/core/shared/AssingProps';
import { Actions } from '@/store/commons/ActionsTable';
import ReceptionRow from '@/models/receptions/ReceptionRow';
import FiltersReceptions from '@/models/receptions/filters/FilterReceptions';
import OptionSelect from '@/models/shared/OptionSelect';
import CustomerComboService from '@/core/services/customer/CustomerComboService';
import TableWarehouseTransfersState from '../../models/TableWarehouseTransfersState';
import TransferRow from '@/models/weewhouse/TransferRow';
import FiltersTransfers from '@/models/weewhouse/filters/FiltersTransfers';
import WarehouseTransfersService from '@/core/services/werehouse/WarehouseTransfersService';
import WarehouseComboService from '@/core/services/werehouse/WarehouseComboService';
import { TransferStatus } from '@/core/enums/transfer-status';
import TableWarehouseTransferDetailsState from '../../models/TableWarehouseTransferDetailsState';
import TransferDetailRow from '@/models/weewhouse/TransferDetailRow';
import FiltersTransferDetails from '@/models/weewhouse/filters/FiltersTransferDetails';
import ProductsService from '@/core/services/products/ProductsService';
import ProductSearchComboService from '@/core/services/products/interfaces/productSearchComboService';
import TableTranferDetailsService from '@/core/services/werehouse/interfaces/TableTransferDetailService';

enum MutationsOwner { 
    SET_SELECT_ROW = "SET_SELECT_ROW"
    , RESET_SELECT_ROW = "RESET_SELECT_ROW"
}
const defaultRow = {
    id: ''
    , createdAt: ''
    , productId: ''
    , productName: ''
    , quantity: 0
    , transferId: ''
    , unitOfMeasurementId: ''
    , unitOfMeasurementName: ''
} as TransferDetailRow;
const defaultFilters = {
    productIds: []
} as FiltersTransferDetails

@Module({dynamic: true, store, namespaced: true, name: Modules.TableTransferDetailsModule})
class TableTransferDetailsModule extends VuexModule implements TableWarehouseTransferDetailsState {
    rowSelected = defaultRow;
    records = [] as TransferDetailRow[];
    filters = defaultFilters;
    totalRecords = 0;
    pagination = {
        currentPage: 1
        , pageSize: 10
    } as PaginationRequest;
    loading = false;
    selects = {
        productOptions: [] as OptionSelect[]
    };

    get getSelectedRow(): TransferDetailRow { 
        return this.rowSelected;
    }
    get getRecordsTable(): TransferDetailRow[] { 
        return this.records;
    }
    get getRowSelected(): TransferDetailRow { 
        return this.rowSelected;
    }

    @Mutation
    [MutationsOwner.SET_SELECT_ROW](value: TransferDetailRow | undefined) {
        if (value) { 
            this.rowSelected = value;
        }
    }
    @Mutation
    [MutationsOwner.RESET_SELECT_ROW](){
        this.rowSelected = defaultRow;
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: TransferDetailRow[]){
        this.records = table;
    }
     @Mutation
    [Mutations.RESET_FILTERS](){
         this.filters = defaultFilters;
    }
    @Mutation
    [Mutations.SET_PAGINATION](pagination: Partial<PaginationRequest>){
        SaveValuesToStore(this.pagination, pagination);
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: Partial<FiltersTransferDetails>) {      
        SaveValuesToStore(this.filters, filters);
    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecords = totalRecords;
    }

     /**
     * Se obtienen los datos de la tabla de transferencias creadas mediante la paginación
     */
     @Action
     async [Actions.SEARCH_TABLE_DATA]() {
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
         const transferId = this.context.rootState[Modules.TableTransfersModule]?.rowSelected?.id ?? '';
         if (transferId) { 
              const resp = (await (service.searchTableTransferDetails(transferId,this.pagination, this.filters)
             .finally(() => this.context.commit(Mutations.SET_VALUE_LOADING, false)))).data ??
             {
                 data: [] as ReceptionRow[]
                , totalPages: 0
                , currentPage: this.pagination.currentPage
         }
        // const data = resp.data.map(r => { return {...r, createdAt: } })
         this.context.commit(Mutations.SET_TABLE_DATA, resp.data);
         this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
         this.context.commit(Mutations.SET_TOTAL_RECORDS, resp.totalRecords);
         }
        
    }
 
     /**
        * Realiza un busqueda con la nueva paginación
        * @param pagination paginación de la tabla
        */
     @Action
     async [Actions.CHANGE_PAGE](pagination: Partial<PaginationRequest>){
           this.context.commit(Mutations.SET_PAGINATION, pagination);
           this.context.dispatch(Actions.SEARCH_TABLE_DATA);
     }
 
     /**
      * Filtros que se aplican y despues se hace la busqueda de los datos
      * @param filters filtros nuevos
      */
     @Action
     async [Actions.UPDATE_FILTERS](filters: Partial<FiltersReceptions>) {
         //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
         this.context.commit(Mutations.SET_FILTERS, filters);
         this.context.commit(Mutations.SET_PAGINATION, {
           currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
         });
         this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
    

     @MutationAction
     async getComboProducts(filterName: string) {
         const comboOptions = await productService.searchProducts(filterName);
         const selects = { ...this.selects }
        if(filterName.trim() == '') return  { selects: { ...selects } }
        
        return { selects: { ...selects, productOptions: comboOptions.data ?? [] } }
    }

    /* @Action
     async searchInfoInputSelected(id: string) {
         return service.getInfo(id)
             .then((reception) => { 
                this.context.commit(MutationsOwner.SET_SELECT_ROW, reception.data ?? defaultRow);

             })
    }*/
} 

const service: TableTranferDetailsService = new WarehouseTransfersService();
const productService: ProductSearchComboService = new ProductsService();


export default getModule(TableTransferDetailsModule);
